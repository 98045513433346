/* =================================== */
/*  2. Helpers Classes
/* =================================== */

/* Box Shadow */
.shadow-md {
  @include box-shadow(0px 0px 50px -35px rgba(0, 0, 0, 0.4) !important);
}

/* Border Radius */

.rounded-lg {
  border-radius: 0.6rem !important;
}

.rounded-top-0 {
  border-top-start-radius: 0px !important;
  border-top-end-radius: 0px !important;
}
.rounded-bottom-0 {
  border-bottom-start-radius: 0px !important;
  border-bottom-end-radius: 0px !important;
}
.rounded-start-0 {
  border-top-start-radius: 0px !important;
  border-bottom-start-radius: 0px !important;
}
.rounded-end-0 {
  border-top-end-radius: 0px !important;
  border-bottom-end-radius: 0px !important;
}

/* Text Size */
.text-0 {
  font-size: 11px !important;
  font-size: 0.6875rem !important;
}
.text-1 {
  font-size: 12px !important;
  font-size: 0.75rem !important;
}
.text-2 {
  font-size: 14px !important;
  font-size: 0.875rem !important;
}
.text-3 {
  font-size: 16px !important;
  font-size: 1rem !important;
}
.text-4 {
  font-size: 18px !important;
  font-size: 1.125rem !important;
}
.text-5 {
  font-size: 21px !important;
  font-size: 1.3125rem !important;
}
.text-6 {
  font-size: 24px !important;
  font-size: 1.5rem !important;
}
.text-7 {
  font-size: 28px !important;
  font-size: 1.75rem !important;
}
.text-8 {
  font-size: 32px !important;
  font-size: 2rem !important;
}
.text-9 {
  font-size: 36px !important;
  font-size: 2.25rem !important;
}
.text-10 {
  font-size: 40px !important;
  font-size: 2.5rem !important;
}
.text-11 {
  @include font-size(2.75rem !important);
}
.text-12 {
  @include font-size(3rem !important);
}
.text-13 {
  @include font-size(3.25rem !important);
}
.text-14 {
  @include font-size(3.5rem !important);
}
.text-15 {
  @include font-size(3.75rem !important);
}
.text-16 {
  @include font-size(4rem !important);
}
.text-17 {
  @include font-size(4.5rem !important);
}
.text-18 {
  @include font-size(5rem !important);
}
.text-19 {
  @include font-size(5.25rem !important);
}
.text-20 {
  @include font-size(5.75rem !important);
}
.text-21 {
  @include font-size(6.5rem !important);
}
.text-22 {
  @include font-size(7rem !important);
}
.text-23 {
  @include font-size(7.75rem !important);
}
.text-24 {
  @include font-size(8.25rem !important);
}
.text-25 {
  @include font-size(9rem !important);
}

.text-11,
.text-12,
.text-13,
.text-14,
.text-15,
.text-16,
.text-17,
.text-18,
.text-19,
.text-20,
.text-21,
.text-22,
.text-23,
.text-24,
.text-25 {
  line-height: 1.3;
}

/* Font Weight */
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

/* Opacity */
.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.opacity-10 {
  opacity: 1;
}

/* Background light */
.bg-light-1 {
  background-color: $gray-200 !important;
}

.bg-light-2 {
  background-color: $gray-300 !important;
}

.bg-light-3 {
  background-color: $gray-400 !important;
}

.bg-light-4 {
  background-color: $gray-500 !important;
}

/* Background Dark */
.bg-dark {
  background-color: #111418 !important;
}

.bg-dark-1 {
  background-color: $gray-900 !important;
}

.bg-dark-2 {
  background-color: $gray-800 !important;
}

.bg-dark-3 {
  background-color: $gray-700 !important;
}

.bg-dark-4 {
  background-color: $gray-600 !important;
}

/* Progress Bar */
.progress-sm {
  height: 0.5rem !important;
}

.progress-lg {
  height: 1.5rem !important;
}

hr {
  opacity: 0.1;
}
